<template>
  <div class="swiper-container about-swiper">
    <div class="swiper-wrapper">
      <div
        class="swiper-slide"
        v-for="productsbox in productsData"
        :key="productsbox.title"
      >
        <div class="title">
          <div class="img">
            <img
              :src="productsbox.url"
              alt=""
            >
          </div>
          <div
            class="name"
            :title="productsbox.title"
          >
            {{productsbox.title}}
          </div>
          <div class="text">
            {{productsbox.text}}
          </div>
          <a href="http://www.iotworld.com.cn/" target="_blank">
            <button>
              <i class="el-icon-arrow-right"></i>
            </button>
          </a>
        </div>
      </div>
    </div>
    <div class="swiper-button-next swiper-button-white"></div>
    <div class="swiper-button-prev swiper-button-white"></div>
  </div>
</template>

<script>
import Swiper from 'swiper';
import 'swiper/dist/css/swiper.min.css';
import 'swiper/dist/js/swiper.min.js';

export default {
  props: ['displays', 'screenWidth'],
  data() {
    return {
      productsData: [
        {
          title: "【YA5】11.6单屏人证机",
          text: "路由器里也有软件在运行，典型的例如微软(Microsoft)公司的Windows、苹果(Apple)公司的IOS与Mac",
          url: require('../../assets/image/products1.jpg'),
        },
        {
          title: "【YB8】8寸闸机头",
          text: "技术设备是实现航空运输的物质基础。主要包括航路、航空港、飞机和通信导航设施等。航路是根据地",
          url: require('../../assets/image/products2.jpg'),
        },
        {
          title: "【YA6】11.6双屏人证机",
          text: "直接蒸发型加湿器通常也被称为纯净型加湿器。纯净型的加湿技术则是加湿领域刚刚采用的新技术，通过分子筛蒸发技术，除去水中的钙",
          url: require('../../assets/image/products3.jpg'),
        },
        {
          title: "【YB3】43寸迎宾考勤机",
          text: "",
          url: require('../../assets/image/products4.jpg'),
        },
        {
          title: "【YC2】半圆球摄像头",
          text: "",
          url: require('../../assets/image/products5.jpg'),
        },
        {
          title: "【YC3】枪机式摄像头",
          text: "",
          url: require('../../assets/image/products6.jpg'),
        },
        {
          title: "【YA7】11.6双屏人证机",
          text: "产品名称即“品牌名称（brand name）”。好的品牌名称必须简洁、易读、易记、易写。美国有为产品取名的专业机构，他们",
          url: require('../../assets/image/products7.jpg'),
        },
        {
          title: "【YA9】10.1迷你人证机",
          text: "从过滤网络流量的角度来看，路由器的作用与交换机和网桥非常相似路由器。但是与工作在网络数据链路层，从物理上划分网段的交换机",
          url: require('../../assets/image/products8.jpg'),
        },
        {
          title: "【YA3】10.1单屏人证机",
          text: "直接蒸发型加湿器通常也被称为纯净型加湿器。纯净型的加湿技术则是加湿领域刚刚采用的新技术，通过分子筛蒸发技术，除去水中的钙",
          url: require('../../assets/image/products9.jpg'),
        },
      ],
      View: 3,
    };
  },
  watch: {
    screenWidth: {
      handler() {
        this.switch();
      },
      deep: true,
    },
  },
  mounted() {
    this.switch();
  },
  methods: {
    // 大图轮播
    switch() {
      if (this.screenWidth <= 425) {
        this.View = 1;
      } else if (this.screenWidth <= 768) {
        this.View = 2;
      } else {
        this.View = 3;
      }
      setTimeout(() => {
        const swiper = new Swiper('.about-swiper', {
          slidesPerView: this.View,
          spaceBetween: 20,
          loop: true,
          followFinger: false,
          observer: true,
          observeParents: true,
          speed: 800,
          // virtualTranslate : true,
          autoplay: {
            delay: 3000,
            disableOnInteraction: false,
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        });
        swiper.slideNext();
      }, 100)

    },
  },
};
</script>

<style lang="scss" scoped>
.swiper-container {
  .swiper-wrapper {
    padding-top: 40px;
    height: auto;
    .swiper-slide {
      position: relative;
      height: auto;
      .title {
        width: 75%;
        margin: auto;
        text-align: center;
        .img {
          width: 100%;
          img {
            width: 100%;
          }
        }
        .name {
          color: white;
          font-size: 20px;
          word-break: break-all;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          margin: 10px 0;
        }
        .text {
          color: white;
          font-size: 15px;
          word-break: break-all;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          margin-bottom: 20px;
        }
        a {
          button {
            outline: none;
            font-size: 20px;
            width: 130px;
            height: 30px;
            line-height: 30px;
            background-color: transparent;
            border: 1px solid white;
            color: white;
            border-radius: 50px;
            cursor: pointer;
          }
          &:hover {
            button {
              background-color: white;
              border: 1px solid black;
              color: black;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1160px) {
  .swiper-container {
    .swiper-wrapper {
      padding-top: 30px;
      height: auto;
      .swiper-slide {
        width: 275px;
        height: auto;
        padding-bottom: 10px;
        .title {
          width: 75%;
          margin: auto;
          text-align: center;
          .img {
            width: 100%;
            img {
              width: 100%;
            }
          }
          .name {
            color: white;
            font-size: 20px;
            word-break: break-all;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            margin: 10px 0;
          }
          .text {
            color: white;
            font-size: 15px;
            word-break: break-all;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            margin-bottom: 20px;
          }
          button {
            font-size: 20px;
            width: 130px;
            height: 30px;
            line-height: 30px;
            background-color: transparent;
            border: 1px solid white;
            color: white;
            border-radius: 50px;
          }
          &:hover {
            button {
              background-color: white;
              border: 1px solid black;
              color: black;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  .swiper-container {
    .swiper-wrapper {
      padding-top: 30px;
      height: auto;
      .swiper-slide {
        width: 275px;
        height: auto;
        padding-bottom: 10px;
        .title {
          width: 75%;
          margin: auto;
          text-align: center;
          .img {
            width: 100%;
            img {
              width: 100%;
            }
          }
          .name {
            color: white;
            font-size: 20px;
            word-break: break-all;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            margin: 10px 0;
          }
          .text {
            color: white;
            font-size: 15px;
            word-break: break-all;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            margin-bottom: 20px;
          }
          button {
            font-size: 20px;
            width: 130px;
            height: 30px;
            line-height: 30px;
            background-color: transparent;
            border: 1px solid white;
            color: white;
            border-radius: 50px;
          }
          &:hover {
            button {
              background-color: white;
              border: 1px solid black;
              color: black;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .swiper-container {
    .swiper-wrapper {
      padding-top: 30px;
      height: auto;
      .swiper-slide {
        position: relative;
        width: 275px;
        height: auto;
        padding-bottom: 10px;
        .title {
          width: 75%;
          margin: auto;
          text-align: center;
          .img {
            width: 100%;
            img {
              width: 100%;
            }
          }
          .name {
            color: white;
            font-size: 20px;
            word-break: break-all;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            margin: 10px 0;
          }
          .text {
            color: white;
            font-size: 15px;
            word-break: break-all;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            margin-bottom: 20px;
          }
          button {
            font-size: 20px;
            width: 130px;
            height: 30px;
            line-height: 30px;
            background-color: transparent;
            border: 1px solid white;
            color: white;
            border-radius: 50px;
          }
          &:hover {
            button {
              background-color: white;
              border: 1px solid black;
              color: black;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 425px) {
  .swiper-container {
    .swiper-wrapper {
      padding-top: 30px;
      height: auto;
      .swiper-slide {
        position: relative;
        width: 275px;
        .title {
          width: 75%;
          margin: auto;
          text-align: center;
          .img {
            width: 100%;
            img {
              width: 100%;
            }
          }
          .name {
            color: white;
            font-size: 20px;
            word-break: break-all;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            margin: 10px 0;
          }
          .text {
            color: white;
            font-size: 15px;
            word-break: break-all;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            margin-bottom: 20px;
          }
          button {
            font-size: 20px;
            width: 130px;
            height: 30px;
            line-height: 30px;
            background-color: transparent;
            border: 1px solid white;
            color: white;
            border-radius: 50px;
          }
          &:hover {
            button {
              background-color: white;
              border: 1px solid black;
              color: black;
            }
          }
        }
      }
    }
  }
}

.hide {
  opacity: 0;
}
</style>
