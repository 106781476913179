<template>
  <div class="swiper-container case-swiper">
    <div class="swiper-wrapper">
      <div
        class="swiper-slide"
        v-for="cases in caseData"
        :key="cases.title"
      >
        <a href="http://www.iotworld.com.cn/" target="_blank">
          <div class="title_box">
            <div class="img">
              <img
                :src="cases.url"
                alt=""
              >
            </div>
            <div
              class="name"
              :title="cases.title"
            >
              {{cases.title}}
            </div>
            <div class="text">
              {{ cases.text}}
            </div>
            <div class="btn">
              more>
            </div>
          </div>
        </a>

      </div>
    </div>
    <div class="swiper-button-next swiper-button-white"></div>
    <div class="swiper-button-prev swiper-button-white"></div>
  </div>
</template>

<script>
import Swiper from 'swiper';
import 'swiper/dist/css/swiper.min.css';
import 'swiper/dist/js/swiper.min.js';

export default {
  props: ['displays', 'screenWidth'],
  data() {
    return {
      caseData: [
        {
          title: "新疆乌鲁木齐消防部队营区访客门禁",
          text: "内部人员刷脸进出，访客人员实行实名制登记进出，同时数据上报中心营区平台，实时监管每个营区的出入数据以及来访数据",
          url: require('../../assets/image/case1.jpg'),
        },
        {
          title: "惠州大亚湾中海油访客门禁",
          text: "内部人员刷脸进出，访客人员通过预约审核，实行人证合一实名制登记进出，同时数据上报中心园区平台，实时监管每个园区的出入数据",
          url: require('../../assets/image/case2.jpg'),
        },
        {
          title: "哈尔滨群力商服人脸识别门禁",
          text: "为业主提供更好的安全服务是物业的本职，人脸识别门禁有助于提升园区的安全，业主人员只要刷脸即可进入，方便快捷，来访人员通过",
          url: require('../../assets/image/case3.jpg'),
        },
      ],
      View: 3,
    };
  },
  watch: {
    screenWidth: {
      handler() {
        this.switch();
      },
      deep: true,
    },
  },
  mounted() {
    this.switch();
  },
  methods: {
    // 大图轮播
    switch() {
      if (this.screenWidth <= 425) {
        this.View = 1;
      } else if (this.screenWidth <= 768) {
        this.View = 2;
      } else {
        this.View = 3;
      }
      setTimeout(() => {
        const swiper = new Swiper('.case-swiper', {
          slidesPerView: this.View,
          spaceBetween: 20,
          loop: true,
          followFinger: false,
          observer: true,
          observeParents: true,
          speed: 800,
          // virtualTranslate : true,
          autoplay: {
            delay: 3000,
            disableOnInteraction: false,
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        });
        swiper.slideNext();
      })

    },
  },
};
</script>

<style lang="scss" scoped>
.swiper-container {
  height: auto;
  width: 100%;
  .swiper-wrapper {
    height: auto;
    .swiper-slide {
      width: 100%;
      height: auto;
      .title_box {
        width: 75%;
        height: 100%;
        margin: auto;
        cursor: pointer;
        .img {
          width: 100%;
          height: auto;
          overflow: hidden;
          img {
            width: 100%;
            height: auto;
            transition: 0.6s;
          }
        }
        .name {
          color: white;
          font-size: 18px;
          line-height: 27px;
          margin: 20px 0;
          padding-left: 10px;
          word-break: break-all;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
        }
        .text {
          padding-left: 10px;
          color: #d2d2d2;
          word-break: break-all;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          margin-bottom: 25px;
        }
        .btn {
          margin: 20px 15px;
          width: 100px;
          height: 30px;
          line-height: 30px;
          border: 1px solid white;
          color: white;
          text-align: center;
          background-color: transparent;
        }
        &:hover {
          background-color: #0e77ea;
          .img {
            img {
              -webkit-transform: scale(1.1);
              -moz-transform: scale(1.1);
              -ms-transform: scale(1.1);
              -o-transform: scale(1.1);
              transform: scale(1.1);
            }
          }
          .btn {
            border-radius: 50px;
            background-color: white;
            color: black;
          }
        }
      }
    }
  }
}

@media (max-width: 1160px) {
  .swiper-slide .title {
    top: 17%;
  }
  .swiper-slide .title h1 {
    color: white;
    font-size: 2em;
    font-style: normal;
    font-family: inherit;
    font-weight: 500;
  }
  .swiper-slide .title p {
    color: white;
    line-height: 180%;
    font-size: 1.3em;
    margin-top: 40px;
    font-style: normal;
    font-family: inherit;
    font-weight: 300;
  }
}
@media (max-width: 1024px) {
  .swiper-container {
    height: auto;
    width: 100%;
    .swiper-wrapper .swiper-slide {
      width: 100%;
      height: auto;
      .img {
        width: 100%;
        height: auto;
        overflow: hidden;
        img {
          width: 100%;
          height: auto;
        }
      }
      .name {
        color: white;
        font-size: 18px;
        line-height: 27px;
      }
    }

    .ani-slide {
      img {
        transform: translate(-1%, 0px) scale(1.1);
      }
      .title {
        h3 {
          transform: translateY(0);
          opacity: 1;
        }
        h2 {
          transform: translateY(0);
          opacity: 1;
        }
        .btn {
          transform: translateY(0);
          opacity: 1;
        }
      }
      .schedule em {
        display: block;
        height: 3px;
        width: 100%;
      }
    }
  }

  .swiper-slide .title h3 {
    font-size: 25px;
    margin: 20px 0;
    opacity: 0;
    transform: translateY(100px);
    transition: all 1s;
  }
  .swiper-slide .title h2 {
    font-size: 36px;
    opacity: 0;
    transform: translateY(100px);
    transition: all 2s;
  }
}
@media (max-width: 768px) {
  .swiper-container {
    height: auto;
    width: 100%;
    .swiper-wrapper {
      height: auto;
      .swiper-slide {
        position: relative;
        width: 275px;
        height: auto;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
        }
        .wk_case_ico {
          width: 100%;
          height: 100%;
          background: url("../../assets/image/case_ico.png") no-repeat center;
          background-size: 100% 100%;
          position: absolute;
          top: 0;
          opacity: 0;
          transition: all 0.3s;
          display: none;
        }
        .wk_case_xian {
          position: absolute;
          top: 5%;
          left: 10%;
          width: 80%;
          height: 90%;
          display: none;
          span {
            position: absolute;
            background: #0ddfba;
            -webkit-transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
            -moz-transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
            -o-transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
            transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
          }
          .wk_span_1 {
            top: 0;
            left: 0;
            width: 0;
            height: 2px;
          }
          .wk_span_2 {
            top: 0;
            right: 0;
            width: 2px;
            height: 0;
          }
          .wk_span_3 {
            right: 0;
            bottom: 0;
            width: 0;
            height: 2px;
          }
          .wk_span_4 {
            bottom: 0;
            left: 0;
            width: 2px;
            height: 0;
          }
        }
        &:hover {
          .wk_case_ico {
            opacity: 1;
            -webkit-opacity: 1;
            -moz-opacity: 1;
          }
          .wk_span_1,
          .wk_span_3 {
            width: 100%;
          }
          .wk_span_2,
          .wk_span_4 {
            height: 100%;
          }
        }
      }
    }
  }
}
@media (max-width: 425px) {
  .swiper-container {
    height: auto;
    width: 100%;
    .swiper-wrapper {
      height: auto;
      .swiper-slide {
        position: relative;
        width: 275px;
        height: auto;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
        }
        .wk_case_ico {
          width: 100%;
          height: 100%;
          background: url("../../assets/image/case_ico.png") no-repeat center;
          background-size: 100% 100%;
          position: absolute;
          top: 0;
          opacity: 0;
          transition: all 0.3s;
          display: none;
        }
        .wk_case_xian {
          position: absolute;
          top: 5%;
          left: 10%;
          width: 80%;
          height: 90%;
          display: none;
          span {
            position: absolute;
            background: #0ddfba;
            -webkit-transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
            -moz-transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
            -o-transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
            transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
          }
          .wk_span_1 {
            top: 0;
            left: 0;
            width: 0;
            height: 2px;
          }
          .wk_span_2 {
            top: 0;
            right: 0;
            width: 2px;
            height: 0;
          }
          .wk_span_3 {
            right: 0;
            bottom: 0;
            width: 0;
            height: 2px;
          }
          .wk_span_4 {
            bottom: 0;
            left: 0;
            width: 2px;
            height: 0;
          }
        }
        &:hover {
          .wk_case_ico {
            opacity: 1;
            -webkit-opacity: 1;
            -moz-opacity: 1;
          }
          .wk_span_1,
          .wk_span_3 {
            width: 100%;
          }
          .wk_span_2,
          .wk_span_4 {
            height: 100%;
          }
        }
      }
    }
  }
}

.hide {
  opacity: 0;
}
</style>
