<template>
	<div class="footer_box">
		<div class="footer">
			<div class="footer_title">
				<router-link tag="h4" to="/about">关于我们</router-link>
				<ul>
					<router-link tag="li" v-for="about in aboutData" :key="about.id"
						:to="{ name: 'About', params: { id: about.id } }">
						{{about.name}}
					</router-link>
				</ul>
			</div>
			<div class="footer_title">
				<h4>服务客户</h4>
				<ul>
					<li v-for="service in serviceData" :key="service.title">
						<a :href="service.url" target="_blank">
							{{service.title}}
						</a>
					</li>
				</ul>
			</div>
			<div class="footer_title">
				<h4>服务热线</h4>
				<ul>
					<li>18585851923</li>
				</ul>
				<h4>邮 箱</h4>
				<ul>
					<li>15519158066@wo.cn</li>
				</ul>
			</div>
			<div class="footer_title">
				<img src="../../assets/image/WeChat.png" alt=""><br>
				<span>关注</span><br>
				<span>官方公众号</span>
			</div>
		</div>
		<div class="text">
			Copyright © 拓宇创业云-人脸时代先行者 <br>
			<img src="../../assets/image/gongshang.png" alt="" style="margin-right:2px;width:13px;height:12px;">
			<a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">贵公网安备 52011502002837号</a>
			&nbsp;&nbsp;&nbsp;&nbsp;
			<a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">黔ICP备2023011467号-1</a>
			<!-- &nbsp;&nbsp;&nbsp;&nbsp;
			Powered by CmsEasy Rss -->
		</div>
	</div>
</template>

<script>
	export default {
		props: ['displays'],
		data() {
			return {
				aboutData: [{
						name: "公司简介",
						id: "introduction",
					},
					{
						name: "服务优势",
						id: "course",
					},
					{
						name: "公司团队",
						id: "team",
					},
				],
				serviceData: [{
						url: "http://www.10086.cn/index/gd/index_200_200.html",
						title: "中国移动通信",
					},
					{
						url: "https://www.china-tower.com/index",
						title: "中国铁塔",
					},
					{
						url: "http://www.sdlckj.com/",
						title: "力创科技",
					},
					{
						url: "https://www.cloudwalk.com/",
						title: "云从科技",
					},
					{
						url: "https://www.inspur.com/",
						title: "浪潮",
					},
					{
						url: "http://www.hxcy.com.cn/",
						title: "杭州华星创业通信",
					},
				],
			}
		},
		mounted() {
			if (this.$route.params.id !== undefined) {
				let id = "#" + this.$route.params.id;
				document.querySelector(id).scrollIntoView(false);
			}
		}
	}
</script>

<style lang="scss" scoped>
	.footer_box {
		width: 100vw;
		height: auto;
		background-color: black;
		padding: 20px 0;

		.footer {
			width: 1160px;
			height: auto;
			margin: auto;
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;

			.footer_title {
				text-align: center;

				h4 {
					color: white;
					font-weight: 500;
					height: 45px;
					line-height: 45px;
					cursor: pointer;
					text-align: left;
				}

				ul {
					li {
						color: #b6b3b3;
						cursor: pointer;
						text-align: left;

						&:hover {
							color: white;
						}

						a {
							color: #b6b3b3;
							text-align: left;

							&:hover {
								color: white;
							}
						}
					}
				}

				img {
					text-align: center;
				}

				span {
					color: #b6b3b3;
				}
			}
		}

		.text {
			width: 1160px;
			margin: auto;
			border-top: 1px solid #eeeeee;
			color: #b6b3b3;
			text-align: center;
			margin-top: 10px;
			padding-top: 10px;

			a {
				color: #b6b3b3;
			}

			img {
				width: 20px;
				height: 20px;
			}
		}
	}

	@media (max-width: 1280px) {
		.footer_box {
			width: 100vw;
			height: auto;
			background-color: black;
			padding: 20px 0;

			.footer {
				width: 80%;
				height: auto;
				margin: auto;
				display: flex;
				flex-flow: row nowrap;
				justify-content: space-between;

				.footer_title {
					h4 {
						color: #c9c9c9;
						font-weight: 500;
						height: 45px;
						line-height: 45px;
					}

					ul {
						li {
							color: #b6b3b3;
						}
					}
				}
			}

			.text {
				width: 100%;
				margin: auto;
				border-top: 1px solid #eeeeee;
				color: #b6b3b3;
				text-align: center;
				margin-top: 10px;
				padding-top: 10px;

				a {
					color: #b6b3b3;
				}

				img {
					width: 20px;
					height: 20px;
				}
			}
		}
	}

	@media (max-width: 1024px) {
		.footer_box {
			width: 100vw;
			height: auto;
			background-color: black;
			padding: 20px 0;

			.footer {
				width: 80%;
				height: auto;
				margin: auto;
				display: flex;
				flex-flow: row nowrap;
				justify-content: space-between;

				.footer_title {
					h4 {
						color: #c9c9c9;
						font-weight: 500;
						height: 45px;
						line-height: 45px;
					}

					ul {
						li {
							color: #b6b3b3;
						}
					}
				}
			}

			.text {
				width: 100%;
				margin: auto;
				border-top: 1px solid #eeeeee;
				color: #b6b3b3;
				text-align: center;
				margin-top: 10px;
				padding-top: 10px;

				a {
					color: #b6b3b3;
				}

				img {
					width: 20px;
					height: 20px;
				}
			}
		}
	}

	@media (max-width: 768px) {
		.footer_box {
			width: 100vw;
			height: auto;
			background-color: black;
			padding: 20px 0;

			.footer {
				width: 80%;
				height: auto;
				margin: auto;
				display: flex;
				flex-flow: row nowrap;
				justify-content: space-between;

				.footer_title {
					h4 {
						color: #c9c9c9;
						font-weight: 500;
						height: 45px;
						line-height: 45px;
					}

					ul {
						li {
							color: #b6b3b3;
						}
					}
				}
			}

			.text {
				width: 100%;
				margin: auto;
				border-top: 1px solid #eeeeee;
				color: #b6b3b3;
				text-align: center;
				margin-top: 10px;
				padding-top: 10px;

				a {
					color: #b6b3b3;
				}

				img {
					width: 20px;
					height: 20px;
				}
			}
		}
	}

	@media (max-width: 650px) {
		.footer_box {
			width: 100vw;
			height: auto;
			background-color: black;
			padding: 20px 0;

			.footer {
				width: 80%;
				height: auto;
				margin: auto;
				display: flex;
				flex-flow: column nowrap;
				justify-content: space-between;

				.footer_title {
					h4 {
						color: #c9c9c9;
						font-weight: 500;
						height: 30px;
						line-height: 30px;
					}

					ul {
						display: none;

						li {
							color: #b6b3b3;
						}
					}
				}
			}

			.text {
				width: 100%;
				margin: auto;
				border-top: 1px solid #eeeeee;
				color: #b6b3b3;
				text-align: center;
				margin-top: 10px;
				padding-top: 10px;

				a {
					color: #b6b3b3;
				}

				img {
					width: 20px;
					height: 20px;
				}
			}
		}
	}

	@media (max-width: 425px) {
		.footer_box {
			width: 100vw;
			height: auto;
			background-color: black;
			padding: 20px 0;

			.footer {
				width: 80%;
				height: auto;
				margin: auto;
				display: flex;
				flex-flow: column nowrap;
				justify-content: space-between;

				.footer_title {
					h4 {
						color: #c9c9c9;
						font-weight: 500;
						height: 30px;
						line-height: 30px;
					}

					ul {
						display: none;

						li {
							color: #b6b3b3;
						}
					}
				}
			}

			.text {
				width: 80%;
				margin: auto;
				border-top: 1px solid #eeeeee;
				color: #b6b3b3;
				text-align: center;
				margin-top: 10px;
				padding: 0 10%;
				padding-top: 10px;

				a {
					color: #b6b3b3;
				}

				img {
					width: 20px;
					height: 20px;
				}
			}
		}
	}
</style>
