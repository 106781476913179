<template>
  <div class="swiper-container  reveal-swiper">
    <div class="swiper-wrapper">
      <div class="swiper-slide">
        <img
          src="../../assets/image/Rotation1.jpg"
          alt=""
        >
        <div class="title">
          <h2>智慧商圈、智慧园区、智慧停车、智慧办公</h2>
          <p></p>
          <div class="bg"></div>
        </div>
      </div>
      <div class="swiper-slide">
        <img
          src="../../assets/image/Rotation2.jpg"
          alt=""
        >
        <div class="title">
          <h2>高档大气的人脸识别迎宾考勤机</h2>
          <p>一款即可用于员工考勤也可用于迎宾使用的高档大气的大屏人脸识别迎宾考勤机，可自主设置提示语，可连接门禁系统，当识别到人员时自动开关门，后台支持考勤数据导出，支持绑定微信通知提示，适用于企业、事业单位等</p>
          <div class="bg"></div>
        </div>
      </div>
      <div class="swiper-slide">
        <img
          src="../../assets/image/Rotation3.jpg"
          alt=""
        >
        <div class="title">
          <h2>人脸采集，实时布控，多端多场所，助力城市安全</h2>
          <p>人脸采集的方式支持人证比对采集、人脸识别、人脸抓拍，可用于各种场所的布控采集，如酒店、网吧等娱乐场所登记使用的人证比对采集，智慧社区、园区使用的人脸门禁数据采集，公共场合的人脸抓拍，后台支持自动匹配布控人员，实时语音警报提醒</p>
          <div class="bg"></div>
        </div>
      </div>
      <div class="swiper-slide">
        <img
          src="../../assets/image/Rotation4.jpg"
          alt=""
        >
        <div class="title">
          <h2>智慧校车，孩子出行实时通知</h2>
          <p>主要通过人脸识别用于学生上下车安全提示考勤，防止学生睡着滞留车内或未上车逗留引起不必要的安全隐患，当学生上下车时软件采取自动抓拍的进行识别比对，上报后台，通过后台通知家长老师，该生已上/下车，同时支持陌生人上车警报提醒</p>
          <div class="bg"></div>
        </div>
      </div>
      <div class="swiper-slide">
        <img
          src="../../assets/image/Rotation5.jpg"
          alt=""
        >
        <div class="title">
          <h2>智慧访客门禁系统，实时安全管控</h2>
          <p>智慧访客门禁系统，针对访客可微信预约审批通行进入或现场人证比对登记进入，支持无证采集登记，长期有效人员可直接刷人脸直接进入，支持每日统计，支持微信预约，适用于园区、写字楼通行人员管理</p>
          <div class="bg"></div>
        </div>
      </div>
    </div>
    <div
      class="swiper-pagination swiper-pagination-white"
      id="swiper-spit"
    ></div>

    <div class="swiper-button-next swiper-button-white" v-if="displays"></div>
    <div class="swiper-button-prev swiper-button-white" v-if="displays"></div>
  </div>
</template>

<script>
import Swiper from 'swiper';
import 'swiper/dist/css/swiper.min.css';
import 'swiper/dist/js/swiper.min.js';

export default {
  props: ['displays'],
  data() {
    return {};
  },
  mounted() {
    this.switch();
  },
  methods: {
    // 大图轮播
    switch() {
      const swiper = new Swiper('.reveal-swiper', {
        loop: true,
        followFinger: false,
        observer: true,
        observeParents: true,
        speed: 800,
        // virtualTranslate : true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.swiper-pagination',
        },
        _on: {
          init() {
            const slide = this.slides.eq(0);
            slide.addClass('ani-slide');
          },
          transitionStart() {
            for (let i = 0; i < this.slides.length; i++) {
              const slide = this.slides.eq(i);
              slide.removeClass('ani-slide');
            }
          },
          transitionEnd() {
            const slide = this.slides.eq(this.activeIndex);
            slide.addClass('ani-slide');
          },
        },
        get on() {
          return this._on;
        },
        set on(value) {
          this._on = value;
        },
      });
      // 鼠标移出隐藏按钮，移入显示按钮
      swiper.el.onmouseover = function () {
        swiper.navigation.$nextEl.removeClass("hide");
        swiper.navigation.$prevEl.removeClass("hide");
      };
      swiper.el.onmouseout = function () {
        swiper.navigation.$nextEl.addClass("hide");
        swiper.navigation.$prevEl.addClass("hide");
      };
      swiper.slideNext();
    },
    // jump() {
    //   this.$router.push('/about');
    // },
  },
};
</script>

<style lang="scss" scoped>
.swiper-container {
  height: auto;
  width: 100%;
  .swiper-wrapper {
    height: auto;
    .swiper-slide {
      position: relative;
      width: 100%;
      height: auto;
      img {
        width: 100%;
        height: 100%;
      }
      .title {
        width: 85vw;
        height: 100vw;
        margin: auto;
        color: white;
        position: absolute;
        left: 5vw;
        padding-left: 5vw;
        top: 70%;
        z-index: 10;
      }
    }
  }

  .ani-slide {
    .title {
      h2 {
        transform: translateY(0);
        opacity: 1;
      }
      p {
        transform: translateY(0);
        opacity: 1;
      }
      .bg {
        width: 100%;
      }
    }
  }
}


.swiper-slide .title h2 {
  position: absolute;
  font-size: 50px;
  font-style: normal;
  font-family: inherit;
  font-weight: 500;
  opacity: 0;
  transform: translateX(100px);
  transition: all 0.5s;
  z-index: 9;
}
.swiper-slide .title p {
  position: absolute;
  font-size: 22px;
  font-weight: 300;
  margin: 20px 0;
  width: 90%;
  top: 70px;
  opacity: 0;
  transform: translateX(100px);
  transition: all 3s;
  z-index: 9;
}

.swiper-slide .title .bg {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 0%;
  height: 100%;
  transition: all 3s;
  background-color: #12253e88;
  z-index: 1;
}
@media (max-width: 1350px) {
  .swiper-container {
  height: auto;
  width: 100%;
  .swiper-wrapper {
    height: auto;
    .swiper-slide {
      position: relative;
      width: 100%;
      height: auto;
      img {
        width: 100%;
        height: 100%;
      }
      .title {
        width: 85vw;
        height: 100vw;
        margin: auto;
        color: white;
        position: absolute;
        left: 5vw;
        padding-left: 5vw;
        top: 55%;
        z-index: 10;
      }
    }
  }

  .ani-slide {
    .title {
      h2 {
        transform: translateY(0);
        opacity: 1;
      }
      p {
        transform: translateY(0);
        opacity: 1;
      }
      .bg {
        width: 100%;
      }
    }
  }
}
  .swiper-slide .title h1 {
    color: white;
    font-size: 2em;
    font-style: normal;
    font-family: inherit;
    font-weight: 500;
  }
  .swiper-slide .title p {
    color: white;
    line-height: 180%;
    font-size: 1.2em;
    margin-top: 10px;
    font-style: normal;
    font-family: inherit;
    font-weight: 300;
  }
}
@media (max-width: 1024px) {
  .swiper-container {
    width: 100%;
    height: auto;
    .swiper-wrapper {
      height: auto;
      .swiper-slide {
        position: relative;
        width: 100% ;
        height: auto;
        img {
          width: 100% ;
          height: 100%;
        }
        .title {
          margin: auto;
          color: white;
          position: absolute;
          padding-left: 50px;
          top: 25%;
          z-index: 10;
        }
      }
    }

    .ani-slide {
      .title {
        h2 {
          transform: translateX(0);
          opacity: 1;
        }
        p {
          transform: translateX(0);
          opacity: 1;
        }
      }
    }
  }
  .swiper-slide .title h2 {
    font-size: 20px;
    opacity: 0;
    transform: translateX(100px);
    transition: all 2s;
  }
  .swiper-slide .title p {
    color: white;
    line-height: 180%;
    font-size: 1em;
    margin-top: 20px;
    font-style: normal;
    font-family: inherit;
    font-weight: 300;
  }
}
@media (max-width: 768px) {
  .swiper-container {
    height: auto;
    width: 100%;
    .swiper-wrapper {
      height: auto;
      .swiper-slide {
        position: relative;
        width: 100% ;
        height: auto;
        img {
          width: 100% ;
          height: 100%;
        }
        .title {
          display: none;
          margin: auto;
          color: white;
          position: absolute;
          padding-left: 50px;
          top: 25%;
          z-index: 10;
        }
      }
    }

    .ani-slide {
      .title {
        h2 {
          transform: translateX(0);
          opacity: 1;
        }
        p {
          transform: translateX(0);
          opacity: 1;
        }
      }
    }
  }
  .swiper-slide .title h2 {
    font-size: 20px;
    opacity: 0;
    transform: translateX(100px);
    transition: all 2s;
  }
  .swiper-slide .title p {
    color: white;
    line-height: 180%;
    font-size: 1em;
    margin-top: 20px;
    font-style: normal;
    font-family: inherit;
    font-weight: 300;
  }
}
@media (max-width: 425px) {
  .swiper-container {
    height: auto;
    width: 100%;
    .swiper-wrapper {
      height: auto;
      .swiper-slide {
        position: relative;
        width: 100%;
        height: auto;
        img {
          width: 100%;
          height: 100%;
        }
        .title {
          display: none;
          margin: auto;
          color: white;
          position: absolute;
          padding-left: 0;
          top: 15%;
          z-index: 10;
        }
      }
    }
  }
}

.hide {
  opacity: 0;
}
</style>
