<template>
  <div id="app">
    <div id="nav">
      <div class="logo">
        <img
          src="./assets/image/logo.png"
          alt=""
          title="贵州拓宇创业科技有限公司"
        >
      </div>
      <div class="route">
        <router-link to="/">网站首页</router-link>
        <router-link to="/about">关于我们</router-link>
        <router-link to="/journalism">新闻资讯</router-link>
        <router-link to="/contact">联系我们</router-link>
      </div>
      <el-button
        @click="drawer = true"
        class="btn"
      >
        <i class="el-icon-s-operation"></i>
      </el-button>

    </div>
    <el-drawer
      :visible.sync="drawer"
      size="100%"
    >
      <router-link to="/">网站首页</router-link>
      <router-link to="/about">关于我们</router-link>
      <router-link to="/journalism">新闻资讯</router-link>
      <router-link to="/contact">联系我们</router-link>
    </el-drawer>
    <router-view />
  </div>
</template>


<script>
export default {
  name: "App",
  data() {
    return {
      drawer: false
    }
  },
  watch: { // watch的第一种写法
    $route(to, from) {
      if (to.name !== from.name) {
        this.drawer = false;
      }
    }
  },


}
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
}
a,
li {
  text-decoration: none;
  list-style-type: none;
}
body::-webkit-scrollbar {
  display: none;
}
#app {
  #nav {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    width: 100vw;
    height: 60px;
    z-index: 11;
    background-color: white;
    .logo {
      img {
        max-width: 380px;
        max-height: 60px;
      }
    }
    .route {
      a {
        font-weight: bold;
        color: #666;
        padding: 0 16px;
        height: 60px;
        overflow: hidden;
        line-height: 60px;
        background: url("./assets/image/ico2.png") no-repeat left center;
        &.router-link-exact-active {
          color: black;
        }
        &:first-child {
          background: none;
        }
        &:hover {
          color: #00AEEF;
        }
      }
    }

    .btn {
      display: none;
      &:hover {
        background-color: transparent;
      }
      &:active {
        border: 1px solid #dcdfe6;
      }
      &:focus {
        background-color: transparent;
        border: 1px solid #dcdfe6;
      }
    }
  }
  .el-drawer__body {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    a {
      font-weight: bold;
      color: #666;
      padding: 0 16px;
      height: 60px;
      overflow: hidden;
      line-height: 60px;
      &.router-link-exact-active {
        color: black;
      }
      &:hover {
        color: #00AEEF;
      }
    }
  }
}

@media (max-width: 650px) {
  #app {
    #nav {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      position: fixed;
      width: 100vw;
      z-index: 11;
      background-color: white;
      .logo {
        img {
          max-width: 150px;
          max-height: 50px;
        }
      }
      .route {
        display: none;
        a {
          font-weight: bold;
          color: #666;
          padding: 0 16px;
          height: 60px;
          overflow: hidden;
          line-height: 60px;
          background: url("./assets/image/ico2.png") no-repeat left center;
          &.router-link-exact-active {
            color: black;
          }
          &:first-child {
            background: none;
          }
          &:hover {
            color: #00AEEF;
          }
        }
      }
      .btn {
        display: block;
        width: 50px;
        margin-right: 10px;
        padding: 15px;
        i {
          color: black;
          font-size: 20px;
          text-align: center;
        }
      }
    }
  }
}
</style>
